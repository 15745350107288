<template>
  <div class="page mb-10">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3 v-if="contentData != null">{{ contentData.title }}</h3>
          <span class="divider"></span>
          <!--          <p>Дүрийн мэдээлэл, тоглосон цувралууд</p>-->
        </div>

        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link v-if="contentData != null" to="/character/all" class="current">
            <span>{{ contentData.title }}</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="flex page-content-body container mx-auto" style="padding: 30px 0;">
      <div class="bg-white payment-info-wrap p-5 rounded-md shadow-2xl" style="flex: 1;">
        <div v-if="isLoading">
          <ProgressSpinner/>
        </div>
        <p v-else class="text-sm" v-html="contentData.body"></p>
      </div>
      <div class="ml-4">
        <div class="bg-primary rounded-md shadow-2xl">
          <div class="account-info">
            <h3 class="font-medium text-accent text-xl mb-5">Дансны мэдээлэл</h3>
            <div class="w-loader" v-if="isLoadingInfo">
              <ProgressSpinner/>
            </div>
            <table v-else class="b-info-table">
              <tr>
                <td>Шилжүүлэх банк</td>
                <td>{{ paymentData.bank_name }}</td>
                <td></td>
              </tr>
              <tr>
                <td>Дансны дугаар</td>
                <td><span id="bankAccount">{{ paymentData.account_number }}</span></td>
                <td>
                  <Button icon="pi pi-copy" v-tooltip.top="'Хуулах'"
                          class="p-button-rounded p-button-danger p-button-outlined" @click="copyTxt('bankAccount')"/>
                </td>
              </tr>
              <tr>
                <td>Данс эзэмшигч</td>
                <td>{{ paymentData.account_holder }}</td>
                <td></td>
              </tr>
              <tr>
                <td>Гүйлгээний утга</td>
                <td><strong id="userId">{{ user.id }}</strong></td>
                <td>
                  <Button icon="pi pi-copy" v-tooltip.top="'Хуулах'"
                          class="p-button-rounded p-button-danger p-button-outlined" @click="copyTxt('userId')"/>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Transfer",
  data() {
    return {
      isLoading: true,
      isLoadingInfo: true
    }
  },
  computed: {
    ...mapGetters(['user', 'contentData', 'paymentData']),
  },
  created() {
    this.$store.dispatch("getContentData", {vm: this, id: 1})
    this.$store.dispatch("getPaymentData", {vm: this})
  },
  methods: {
    copyTxt(id) {
      let clipTxt = document.querySelector(`#${id}`)
      navigator.clipboard.writeText(clipTxt.textContent);
      this.$toast.success('Амжилттай хуулагдлаа.');
    },
  }
}
</script>

<style scoped>
.content-page {
  height: 100vh;
  padding-top: 100px;
}
</style>